enum activateStatusEnum {
	OK = 'OK',
	activationCodeUnexist = 'UNEXIST_ACTIVATION_CODE',
	activationCodeKO = 'WRONG_ACTIVATION_CODE',
	emailActivationKO = 'EMAIL_ALREADY_EXIST',
	alreadyActive = 'ALREADY_ACTIVE',
	activationCodeKOAndAlreadyActive = 'WRONG_ACTIVATION_CODE_AND_ALREADY_ACTIVE',
	formatEmail = 'FORMAT_EMAIL',
	creationNonAuthorized = 'CREATION_NON_AUTHORIZED',
	temporaryEmail = 'TEMPORARY_EMAIL',
	fullInbox = 'FULL_INBOX',
	emailNotFound = 'EMAIL_NOT_FOUND',
	invalidPhone = 'INVALID_PHONE'
}

export default activateStatusEnum
